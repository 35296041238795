import './App.css';
import React, { useState } from 'react';
import { TextField, Button, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';

const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
const API_KEY = process.env.REACT_APP_API_KEY;       
const findMatchingName = (fullName, nameList) => {
  const fullNameParts = fullName.toLowerCase().trim().split(' ').map((part) => part.trim().replace(' ', ''));

  // Iterate through the nameList
  for (const name of nameList) {
    // Split the current name in the list by spaces
    const nameParts = name[0].toLowerCase().split(' ');

    // Check if all parts of the fullName are present in the current name in the list
    const isMatch = fullNameParts.every((part) => nameParts.includes(part));

    // If a match is found, return the matching name
    if (isMatch) {
      return name;
    }
  }

  // If no match is found, return null
  return null;
};

function App() {
  const [searchText, setSearchText] = useState('');
  const [result, setResult] = useState('');
  const [isMatchFound, setIsMatchFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    try {

      // Fetch data from Google Sheets
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/A:A?key=${API_KEY}`
      );
      const sheetData = response.data.values;

      // Split entered full name by spaces
      // const enteredNames = searchText.toLowerCase().split(' ');

      // const matchCount = enteredNames.filter((enteredName) =>
      //     sheetData.some((row) => row[0].toLowerCase().includes(enteredName))
      //   ).length;

      //   setIsMatchFound(matchCount >= threshold);
      //   setIsLoading(false); // Set loading to false after the request is complete
      // if(matchCount >= threshold){
      //   setResult(`Congratulations, ${searchText}'s passport is available!`);
      //   setIsMatchFound(true);
      // } else {
      //   setResult(`${searchText} passport is not available!`);
      //   setIsMatchFound(false);
      // }
      const matchingName = findMatchingName(searchText, sheetData);
      if(matchingName){
        setResult(`Congratulations, ${searchText}'s passport is available!`);
        setIsMatchFound(true);
      } else {
        setResult(`${searchText} passport is not available!`);
        setIsMatchFound(false);
      }
      
    } catch (error) {
      setResult(`Error fetching data from Google Sheets: ${error}`);
      setIsMatchFound(false);
      setIsLoading(false); // Set loading to false after the request is complete
    }
  };

  const isSearchDisabled = searchText.trim().split(' ').length < 2;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Typography variant="h6">Enter your full name as provided in your passport</Typography>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <img src={require('./images/ncabc.png')} alt="Logo" width="250" height="230"/>
      </div>
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <TextField
          label="Enter Full Name"
          variant="outlined"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: '350px' }} 
        />
        <Button variant="contained" onClick={handleSearch} style={{ marginLeft: '10px' }} disabled={searchText === ''|| isSearchDisabled ||isLoading}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Search'}
        </Button>
      </div>
      <Typography variant="h6" style={{ color: isMatchFound ? 'green' : 'red' }}>
      {isMatchFound  && (
        <Typography variant="h6" style={{ color: 'green' }}>
          {result}
        </Typography>
      )}
      {!isMatchFound  && (
        <Typography variant="h6" style={{ color: 'red' }}>
          {result}
        </Typography>
      )}
      </Typography>
    </div>
  );
}

export default App;
